import React from "react"
import { graphql } from "gatsby"

import { SEO, Slideshow } from "../components"
import { isServer, convertNotFoundQuery } from "../utilities"

import { NotFoundQueryData, TextContent, Photo } from "../types"

interface Props {
  data: NotFoundQueryData
  location: Window["location"]
}

const NotFoundPage = ({ data, location }: Props) => {
  const { photos } = convertNotFoundQuery({ data })

  const photo = getRandomPhoto(photos)

  const content: TextContent = {
    type: "TextContent",
    content: [
      { type: "BackLink", value: "/" },
      { type: "Title", value: "Not Found" },
      {
        type: "Paragraph",
        value: "Page could not be found!",
      },
    ],
  }

  return (
    <>
      <SEO
        images={[photo.feature]}
        meta={[
          {
            property: "og:type",
            content: "website",
          },
        ]}
        title="404"
        path={location.pathname}
      />
      {isServer() ? null : <Slideshow items={[photo.feature, content]} />}
    </>
  )
}

function getRandomPhoto(photos: Photo[]) {
  return photos[Math.floor(Math.random() * photos.length)]
}

export default NotFoundPage

export const query = graphql`
  query NotFound {
    contentfulCollection(title: { eq: "Main" }) {
      images {
        id
        handle
        excerpt {
          excerpt
        }
        product
        title
        image {
          description
          fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
            srcSet
            srcSetWebp
            src
            srcWebp
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
        previewPhotos {
          image {
            fluid(quality: 50, maxHeight: 2000, maxWidth: 2000) {
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    allShopifyProductVariant {
      edges {
        node {
          shopifyId
          priceV2 {
            amount
            currencyCode
          }
          availableForSale
        }
      }
    }
  }
`
