import { NotFoundQueryData, Photo } from "../../types"

import { convertImageNode } from "./common"

interface Input {
  data: NotFoundQueryData
}

interface Output {
  photos: Photo[]
}

export function convertNotFoundQuery({ data }: Input): Output {
  if (
    data.contentfulCollection == null ||
    data.contentfulCollection.images == null
  ) {
    throw new Error("No collection found")
  }

  return {
    photos: data.contentfulCollection.images.map(imageNode =>
      convertImageNode(imageNode, data.allShopifyProductVariant)
    ),
  }
}
